<template lang="pug">
    header.check#check
        h1 Test the speed of your site from {{ this.country }}
        h2 and get a free download speed audit
        FormWrapper.form(method="post" action="/mail" data-goal="forma_top")
            .form-body
                label
                    span enter a site address to verify
                    input.form-item(name="site" id="TheCheck-site" placeholder="Enter a site address" autocapitalize="off" required autofocus)
                label.city
                    span From which city to check?
                    // selectCity(:default="{server: 'main'}" class="select")
                    select(name="server" class="ct" @change="changeCountry($event.currentTarget)")
                      optgroup(label="Russia")
                        option(value="main" :selected="!this.$cookies.get('server') || this.$cookies.get('server') === 'main'") Moscow
                        option(value="spb-1" :selected="this.$cookies.get('server') === 'spb-1'") St. Petersburg
                        option(value="krd-1" :selected="this.$cookies.get('server') === 'krd-1'") Krasnodar
                        option(value="smr-1" :selected="this.$cookies.get('server') === 'smr-1'") Samara
                        option(value="krm-1" :selected="this.$cookies.get('server') === 'krm-1'") Simferopol
                        option(value="ekb-1" :selected="this.$cookies.get('server') === 'ekb-1'") Yekaterinburg
                        option(value="krs-1" :selected="this.$cookies.get('server') === 'krs-1'") Krasnoyarsk
                        option(value="hbr-1" :selected="this.$cookies.get('server') === 'hbr-1'") Khabarovsk
                        option(value="vvo-1" :selected="this.$cookies.get('server') === 'vvo-1'") Vladivostok
                      optgroup(label="Belarus")
                        option(value="mns-1" :selected="this.$cookies.get('server') === 'mns-1'") Minsk
                      optgroup(label="Kazakhstan")
                        option(value="alm-1" :selected="this.$cookies.get('server') === 'alm-1'") Almaty
                      optgroup(label="India")
                        option(value="blr-1" :selected="this.$cookies.get('server') === 'blr-1'") Bangalore
                      optgroup(label="Japan")
                        option(value="tyo-1" :selected="this.$cookies.get('server') === 'tyo-1'") Tokyo
                      optgroup(label="Australia")
                        option(value="syd-1" :selected="this.$cookies.get('server') === 'syd-1'") Sydney
                      optgroup(label="Singapore")
                        option(value="sgp-1" :selected="this.$cookies.get('server') === 'sgp-1'") Singapore
                      optgroup(label="Canada")
                        option(value="tor-1" :selected="this.$cookies.get('server') === 'tor-1'") Toronto
                      optgroup(label="USA")
                        option(value="nyc-1" :selected="this.$cookies.get('server') === 'nyc-1'") New-York
                      optgroup(label="UK")
                        option(value="lon-1" :selected="this.$cookies.get('server') === 'lon-1'") London
                      optgroup(label="France")
                        option(value="par-1" :selected="this.$cookies.get('server') === 'par-1'") Paris
                      optgroup(label="Netherlands")
                        option(value="ams-1" :selected="this.$cookies.get('server') === 'ams-1'") Amsterdam
                      optgroup(label="Germany")
                        option(value="fra-1" :selected="this.$cookies.get('server') === 'fra-1'") Frankfurt
                client-only
                    input.form-item.email(v-if="show_email" name="email" type="email" placeholder="Enter your e-mail" autocapitalize="off" required)
                FormButton Analyze
            client-only
                fz(v-if="show_email")
            LazyHydrate(ssr-only)
                p {{ horror }}
</template>

<script>
    import FormWrapper from "~/components/FormWrapper";
    import FormButton from "~/components/FormButton";
    import Fz from "~/components/fz";
    // import ThePopup from "~/components/ThePopup";
    import LazyHydrate from 'vue-lazy-hydration';
    // import selectCity from "~/components/selectCity";

    export default {
        name: "TheCheck",
        components: {
            Fz,
            FormWrapper,
            FormButton,
            // ThePopup,
            LazyHydrate,
            // selectCity
        },
        mounted() {
            for (let input of this.$el.getElementsByTagName("input")) {
                input.setAttribute("autocorrect", "off")
            }
            if (process.browser && !navigator.userAgent.includes("Chrome-Lighthouse")) {
                this.horror = this.horrorTextList[Math.floor(Math.random() * this.horrorTextList.length)];
            }
            this.changeCountry({value: this.$cookies.get('server')});
        },
        methods: {
          changeCountry(server) {
            if (server && server.value) {
              if (server.value === 'alm-1') {
                this.country = 'Kazakhstan';
              } else if (server.value === 'blr-1') {
                this.country = 'India';
              } else if (server.value === 'syd-1') {
                this.country = 'Australia';
              } else if (server.value === 'tyo-1') {
                this.country = 'Japan';
              } else if (server.value === 'sgp-1') {
                this.country = 'Singapore';
              } else if (server.value === 'tor-1') {
                this.country = 'Canada';
              } else if (server.value === 'nyc-1') {
                this.country = 'USA';
              } else if (server.value === 'lon-1') {
                this.country = 'UK';
              } else if (server.value === 'par-1') {
                this.country = 'France';
              } else if (server.value === 'fra-1') {
                this.country = 'Germany';
              } else if (server.value === 'ams-1') {
                this.country = 'Netherlands';
              } else if (server.value === 'mns-1') {
                this.country = 'Belarus';
              } else {
                this.country = 'Russia';
              }
              if (server.value !== '') {
                this.$cookies.set("server", server.value, {maxAge: 7 * 24 * 3600});
              }
            }
          }
        },
        computed: {
            show_email() {
                return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed
            }//,
            // show_popup() {
            //     return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed && !this.showed_popup
            // }
        },
        data() {
            return {
                showed_popup: false,
                horrorTextList: [
                    "Up to 88% of customers will not return to a slow site.",
                    "1 second of slowdown costs $1.6 billion a year for Amazon.",
                    "Fast sites get 20% more organic traffic.",
                    "Fast sites get 14% more page views.",
                    "Fast sites give 7% to 12% more conversions than slow ones."
                ],
                country: 'Russia',
                horror: ''
            }
        }
    }
</script>


<style lang="stylus">
    @require "~assets/style/VAR.styl"

    header.check
        color #fff
        min-height 90vh
        display flex
        flex-direction column
        background night-sky
        padding 70px 20px 50px
        align-items center
        justify-content center
        position relative
        .hint
            .title
                font-size 28px
            .btn.grey
                background #999
                margin-bottom 15px
        h1
            font-weight normal
            font-size 30px
            margin-bottom 30px
            text-align center
            line-height 30px
        h2
            font-weight normal
            font-size 20px
            margin-bottom 50px
            margin-top -25px
            text-align center
        p
            margin 30px 0 30px 0
            text-align center
        .des
            margin-bottom 10px
        .form
            width 100%
            max-width 820px
            .form-body
                label
                    width 100%
                    position relative
                    span
                        display block
                        position absolute
                        width 10px
                        height 10px
                        font-size 0
                        color dark
                    &.city
                        width auto
                width 100%
                display flex
                flex-direction column
                justify-content center
                position relative
                input
                    width 100%
                    display block
                    border 0
                    padding 10px 20px
                    line-height 20px
                    outline 0
                    font-size 18px
                    -webkit-appearance none
                    border-radius 3px 3px 0 0
                    height 50px!important
                    margin 0!important
                    &:focus
                        border-color #d9dee1!important
                    &.email
                        border-radius 0
                        border-top 1px solid #c4c4c4
                .city
                    position relative
                    select
                        cursor pointer
                        appearance none
                        border 0
                        border-radius 0
                        outline none
                        margin 0
                        color purple
                        font-size 18px
                        padding 0 10px 0 30px
                        font-family Play
                        min-height 50px
                        line-height 50px
                        display flex
                        align-items center
                        justify-content center
                        width 100%
                        min-width 170px
                        background #f5f5f5
                        &:hover
                            background #e5e5e5
                    &:after
                        content '▾'
                        display block
                        position absolute
                        left 10px
                        top 15px
                        color purple
                        font-size 16px
                        pointer-events none
                button
                    height 50px!important
                    padding 0 40px
                    border-radius 0 0 3px 3px
                    margin 0
                a
                    background purple
                    height 50px!important
                    color #fff
                    border 0
                    width 320px
                    max-width 100%
                    text-transform uppercase
                    font-size 20px
                    padding 0 40px
                    margin auto
                    border-radius 3px
                    text-decoration none
                    box-shadow 0 20px 50px rgba(0, 180, 255, .3)
                    display flex
                    justify-content center
                    align-items center

    @media screen and (min-width: tablet-width)
        header.check
            h1
                font-size 40px
                margin-bottom 40px
                line-height 40px
            h2
                font-size 24px
            form.form
                .form-body
                    box-shadow 0 20px 50px rgba(0, 180, 255, .3)
                    flex-direction row
                    input
                        border-radius 3px 0 0 3px
                        font-size 16px
                        &.email
                            border-radius 0
                            border-top 0
                            border-left 1px solid #c4c4c4
                    .city
                        select
                            font-size 16px
                    button
                        width auto!important
                        border-radius 0 3px 3px 0
                        cursor pointer
                        &:hover
                            background rgba(213, 32, 85, .8)
                        &:active
                            transform: scale(.98)
                    a
                        width auto!important
                        border-radius 3px
                        cursor pointer
                        &:hover
                            background rgba(213, 32, 85, .8)
                        &:active
                            transform: scale(.98)

    @media screen and (min-width: desktop-width)
        .popup
            .form-item
                height 50px
                line-height 30px
                font-size 19px
                &::-webkit-input-placeholder
                    font-size 15px
            input.form-item:-moz-placeholder
                font-size 15px
</style>

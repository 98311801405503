<template lang="pug">
    .block.scanner-screen(v-if="$store.state.scanner.status")
        .float-scan-title(v-if="float.show" :style="{top: float.top, bottom: float.bottom}")
            .text(@click="go_to_scan")
                strong Scanning:
                    span(v-if="$store.state.scanner.status === 'progress'")  {{float.percents}}%
                p(v-if="$store.state.scanner.status === 'wait'") {{ $store.state.scanner.queue ? 'You\'re {queue} in line...'.replace("{queue}", $store.state.scanner.queue) : 'Please wait for...' }}
                p(v-else) {{ float.text }}
        .wrapper
            .wait-screen(v-if="$store.state.scanner.status === 'wait' || $store.state.scanner.status === 'progress'")
                .title Testing the site
                    |
                    |
                    a(:href="site_full_url" target="_blank") {{ site_domain }}
                    span From
                        span(v-if="$store.state.scanner.server === 'main'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-1-monitor'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-monitor'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-1'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-2'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-3'") Moscow
                        span(v-if="$store.state.scanner.server === 'spb-1'") St. Petersburg
                        span(v-if="$store.state.scanner.server === 'smr-1'") Samara
                        span(v-if="$store.state.scanner.server === 'krm-1'") Simferopol
                        span(v-if="$store.state.scanner.server === 'ekb-1'") Yekaterinburg
                        span(v-if="$store.state.scanner.server === 'krd-1'") Krasnodar
                        span(v-if="$store.state.scanner.server === 'krs-1'") Krasnoyarsk
                        span(v-if="$store.state.scanner.server === 'hbr-1'") Khabarovsk
                        span(v-if="$store.state.scanner.server === 'vvo-1'") Vladivostok
                        span(v-if="$store.state.scanner.server === 'alm-1'") Almaty
                        span(v-if="$store.state.scanner.server === 'blr-1'") Bangalore
                        span(v-if="$store.state.scanner.server === 'syd-1'") Sydney
                        span(v-if="$store.state.scanner.server === 'tyo-1'") Tokyo
                        span(v-if="$store.state.scanner.server === 'sgp-1'") Singapore
                        span(v-if="$store.state.scanner.server === 'tor-1'") Toronto
                        span(v-if="$store.state.scanner.server === 'nyc-1'") New-York
                        span(v-if="$store.state.scanner.server === 'lon-1'") London
                        span(v-if="$store.state.scanner.server === 'par-1'") Paris
                        span(v-if="$store.state.scanner.server === 'fra-1'") Frankfurt
                        span(v-if="$store.state.scanner.server === 'ams-1'") Amsterdam
                        span(v-if="$store.state.scanner.server === 'mns-1'") Minsk

                    small.bl(v-if="$store.state.scanner.status === 'progress'") {{ float.percents }}%
                .text
                    div(v-if="$store.state.scanner.status === 'wait'") {{ $store.state.scanner.queue ? 'You\'re {queue} in line...'.replace("{queue}", $store.state.scanner.queue) : 'Please wait for...' }}
                    // .lnk(v-if="$store.state.scanner.queue > 5" @click="console.log('ok')") I don't want to wait
                    .analysis(v-if="$store.state.scanner.status === 'progress'") {{ float.text }}
                .image
                    .horror(:style="{opacity: horror_opacity}") {{ horror_text }}
                    img.cloud.back(:src="require('~/assets/images/cloud-back.svg')")
                    img.cloud.middle(:src="require('~/assets/images/cloud-middle.svg')")
                    img.cloud.front(:src="require('~/assets/images/cloud-front.svg')")
                .banner
                    a.btn(href="https://скорость-сайта.рф/auto-check-speed/" target="_blank") Autochecks
                    a.btn(href="https://l.iw.media/fastvps" target="_blank") Fast hosting
                    a.btn(href="https://l.iw.media/w-tools" target="_blank") Reliable CDN
                    a.btn(href="https://скорость-сайта.рф/instruction-speedup/" target="_blank") Detailed audit

            .error-screen(v-else-if="$store.state.scanner.status === 'error' || $store.state.scanner.status === 'failure' || $store.state.scanner.result.load === null")
                .title Failed to complete the scan
                    small
                        | Unfortunately, we could not measure the loading speed of your site.
                        br
                        | Make sure the site address is correct, and copy it from the address bar of your browser.
                        br
                        | We limited one check to 60 seconds.
                .image
                    img.cloud.back(:src="require('~/assets/images/cloud-back.svg')")
                    img.cloud.middle(:src="require('~/assets/images/cloud-middle.svg')")
                    img.cloud.front(:src="require('~/assets/images/cloud-front.svg')")
                    a.horror(:href="`/?scan_url=${site_full_url}`") Re-check
                small
                    | Check your site through «
                    a(href="https://gtmetrix.com/" target="_blank" rel="nofollow") GTMetrix
                    | » and «
                    a(href="https://developers.google.com/speed/pagespeed/insights/?hl=ru" target="_blank" rel="nofollow") Google PageSpeed Insights
                    | », to understand the reason for this error.
                    br
                    | If your site is taking a long time to load, we are always happy to help guarantee that it loads faster.
            .result-screen(v-else-if="$store.state.scanner.status === 'result'")
                .title
                    | Site speed test results
                    br
                    | from the 3G network on your mobile device
                    br
                    a.bl(:href="$store.state.scanner.site" target="_blank")
                        | {{ site_domain }}
                    span from
                        span(v-if="$store.state.scanner.server === 'main'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-1-monitor'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-monitor'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-1'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-2'") Moscow
                        span(v-if="$store.state.scanner.server === 'msk-3'") Moscow
                        span(v-if="$store.state.scanner.server === 'spb-1'") St. Petersburg
                        span(v-if="$store.state.scanner.server === 'smr-1'") Samara
                        span(v-if="$store.state.scanner.server === 'krm-1'") Simferopol
                        span(v-if="$store.state.scanner.server === 'krd-1'") Krasnodar
                        span(v-if="$store.state.scanner.server === 'ekb-1'") Yekaterinburg
                        span(v-if="$store.state.scanner.server === 'krs-1'") Krasnoyarsk
                        span(v-if="$store.state.scanner.server === 'hbr-1'") Khabarovsk
                        span(v-if="$store.state.scanner.server === 'vvo-1'") Vladivostok
                        span(v-if="$store.state.scanner.server === 'alm-1'") Almaty
                        span(v-if="$store.state.scanner.server === 'blr-1'") Bangalore
                        span(v-if="$store.state.scanner.server === 'syd-1'") Sydney
                        span(v-if="$store.state.scanner.server === 'tyo-1'") Tokyo
                        span(v-if="$store.state.scanner.server === 'sgp-1'") Singapore
                        span(v-if="$store.state.scanner.server === 'tor-1'") Toronto
                        span(v-if="$store.state.scanner.server === 'nyc-1'") New-York
                        span(v-if="$store.state.scanner.server === 'lon-1'") London
                        span(v-if="$store.state.scanner.server === 'par-1'") Paris
                        span(v-if="$store.state.scanner.server === 'fra-1'") Frankfurt
                        span(v-if="$store.state.scanner.server === 'ams-1'") Amsterdam
                        span(v-if="$store.state.scanner.server === 'mns-1'") Minsk

                .screenshot
                    .sticky
                        .screen
                            img(:src="screenshot" :alt="site_domain")
                            .iphone
                            .fake(v-if="$store.state.scanner.result.domSize < 20") Perhaps the developers of this site are trying to fool the speed measurement systems.
                        .date
                            strong Test Time
                            .datetime {{ testDate }}
                .result
                    .score
                        .value(:class="`rate-${total_rate}`")
                            .speed(v-if="total_rate > 8") Fast
                            .speed(v-else-if="total_rate > 4") Medium
                            .speed(v-else) Slow
                            strong {{ total_rate }}/10
                            .rate-bar
                                i(v-for="point in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :style="{opacity: point <= total_rate ? 1: 0}")
                            FormWrapper(method="post" action="/mail" data-goal="forma_top")
                                input(name="site" id="TheCheck-site" type="hidden" :value="site_full_url")
                                client-only
                                    p.more(v-if="show_email") More checks are needed?
                                    input.form-item.email(v-if="show_email" name="email" type="email" placeholder="Enter your e-mail" autocapitalize="off" required)
                                    input.ct(name="server" :value="$store.state.scanner.server" type="hidden")
                                FormButton.retest Re-check
                                client-only
                                    fz(v-if="show_email")
                        form(@submit.prevent="submit" v-if="other_site")
                            .text
                                strong Thank you for your interest, we are pleased ;)
                                span We are confident that everything is okay with our site because we use site load speed monitoring.
                                a.btn.getMessangerLinks.pulse(href="https://скорость-сайта.рф/auto-check-speed/" target="_blank") I want monitoring, too!
                        form(@submit.prevent="submit" v-else)
                            .text(v-if="total_rate > 8")
                                strong You did a great job!
                                span Connect automatic speed checks for your site! Checks every 15 minutes from 5 cities in Russia. In case of slowdowns you will be notified by email.
                                a.btn.getMessangerLinks.pulse(href="https://скорость-сайта.рф/auto-check-speed/" target="_blank") Learn more
                            .text(v-else)
                                strong(v-if="total_rate < 5") Your site loads very slowly
                                strong(v-else) Your site should work faster
                                span Get a free developer audit for your website. We work with WordPress, Bitrix, Opencart, Drupal, Magento, Vue and other CMS and frameworks. Acceleration Experience 8 years.
                                .btn.getMessangerLinks.pulse.icon(v-if="!getAudit" @click="getMessengerLinks") Free site audit
                                .share(v-if="getAudit")
                                    .item.grey#getmailaudit(@click="clickMailAudit") Get the audit by mail
                                    a.item.green(target="_blank" href="https://pagespeed.loading.express") Instructions for improving PageSpeed
                                    .item.red#getphoneaudit(@click="clickPhoneAudit") Quick advice by phone
                    .preview
                        .preview-item(v-for="thumbs in $store.state.scanner.result.preview")
                            img(:src="thumbs.data" :alt="`Screenshot of the site via ${thumbs.timing} milliseconds after the start of loading`")
                            .timing {{ thumbs.timing }}&nbsp;ms
                    client-only
                        coreWebVitals(:url="$store.state.scanner.site")
                    .metrics
                        .metrics-item(:class="`rate-${rate}`" @click="hint_full =! hint_full")
                            .metrics-item-title Full Loading Time
                            .metrics-item-value {{ full_time }} sec.
                            .metrics-item-help ?
                        .metrics-item(:class="`rate-${Requests_rate}`" @click="hint_request =! hint_request")
                            .metrics-item-title Number of HTTP-requests
                            .metrics-item-value {{ $store.state.scanner.result.requests }}
                            .metrics-item-help ?
                        .metrics-item(:class="`no-border rate-${Size_rate}`" @click="hint_size =! hint_size")
                            .metrics-item-title Total page size
                            .metrics-item-value {{ full_size_string }}
                            .metrics-item-help ?
                        .btn.grey(@click="moreTarget")
                            span.open(v-if="moreDetail") Hide details
                            span.close(v-else) Show details
                        .metrics-item(:class="`small rate-${ttfb_rate}`" v-show="moreDetail" @click="hint_ttfb =! hint_ttfb")
                            .metrics-item-title Server response time
                            .metrics-item-value {{ $store.state.scanner.result.TTFB }} ms
                            .metrics-item-help ?
                        .metrics-item(:class="`small rate-${onload_rate}`" v-show="moreDetail" @click="hint_html =! hint_html")
                            .metrics-item-title Full load time
                            .metrics-item-value {{ domContentLoaded }} sec.
                            .metrics-item-help ?
                        .metrics-item(:class="`small no-border rate-${FCP_rate}`" v-show="moreDetail" @click="hint_fcp =! hint_fcp")
                            .metrics-item-title First content rendering
                            .metrics-item-value {{ $store.state.scanner.result.FCP }} ms
                            .metrics-item-help ?
                        .metrics-item(:class="`small rate-${FID_rate}`" v-show="moreDetail" @click="hint_fid =! hint_fid")
                            .metrics-item-title Total blocking Time
                            .metrics-item-value {{ $store.state.scanner.result.FID }} ms
                            .metrics-item-help ?
                        .metrics-item(:class="`small rate-${EvaluateScript_rate}`" v-show="moreDetail" @click="hint_js =! hint_js")
                            .metrics-item-title Javascript runtime
                            .metrics-item-value {{ $store.state.scanner.result.EvaluateScript }} ms
                            .metrics-item-help ?
                        .metrics-item(:class="`small no-border rate-${LastInteractive_rate}`" v-show="moreDetail" @click="hint_interactive =! hint_interactive")
                            .metrics-item-title Time to Interaction
                            .metrics-item-value {{ full_time }} sec.
                            .metrics-item-help ?
                        .line(v-show="moreDetail" v-if="$store.state.scanner.result.compress_save > 100000")
                            .label Lossless image compression will save
                            .value
                                span  {{ compress_save_string }}
                        .line(v-show="moreDetail" v-if="$store.state.scanner.result.offscreen_image > 100000")
                            .label Delayed image loading will save
                            .value
                                span  {{ offscreen_image_string }}
                        .line(v-show="moreDetail" v-if="$store.state.scanner.result.cache_save > 100000")
                            .label Caching static files will save
                            .value
                                span  {{ cache_save_string }}
                        .line(v-show="moreDetail" v-if="$store.state.scanner.result.webp_save > 100000")
                            .label Using the WebP format for images will save
                            .value
                                span  {{ webp_save_string }}
                        .resources(v-if="moreDetail")
                            table
                                thead
                                    tr
                                        td Resource type
                                        td Requests
                                        td Data amount
                                tbody
                                    tr
                                        td
                                            details
                                                summary Documents
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.resourceType === 'Document'")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.docs.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.docs.size) }}
                                    tr
                                        td
                                            details
                                                summary Styles
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.resourceType === 'Stylesheet'")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.style.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.style.size) }}
                                    tr
                                        td
                                            details
                                                summary Fonts
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.resourceType === 'Font'")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.fonts.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.fonts.size) }}
                                    tr
                                        td
                                            details
                                                summary JavaScript
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.resourceType === 'Script'")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.scripts.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.scripts.size) }}
                                    tr
                                        td
                                            details
                                                summary Images
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.resourceType === 'Image'")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.images.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.images.size) }}
                                    tr
                                        td
                                            details
                                                summary Audio/Video
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.resourceType === 'Media'")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.media.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.media.size) }}
                                    tr
                                        td
                                            details
                                                summary External resources
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amount
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="item.url.indexOf($store.state.scanner.site.replace(/.+?:../,'://').replace(/\\?.+/,'')) < 0")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.ext.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.ext.size) }}
                                    tr
                                        td
                                            details
                                                summary Other
                                                table
                                                    thead
                                                        tr
                                                            td Address
                                                            td Data amoun
                                                            td
                                                                | Time
                                                                br
                                                                | Download
                                                    tbody
                                                        tr(v-for="item in $store.state.scanner.result.requestsItem" v-if="(item.resourceType === 'XHR')||(!item.resourceType)")
                                                            td
                                                                a(:href="item.url" target="_blank" :title="item.url") {{ getUrlPath(item.url) }}
                                                            td {{ size_human(item.transferSize) }}
                                                            td {{ Math.round(item.endTime - item.startTime) }} ms
                                        td {{ $store.state.scanner.result.resourceSummary.other.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.other.size) }}
                                    tr.all
                                        td Total
                                        td {{ $store.state.scanner.result.resourceSummary.total.requests }}
                                        td {{ size_human($store.state.scanner.result.resourceSummary.total.size) }}
                        .btn.grey(@click="jsDetail =! jsDetail" v-if="moreDetail")
                            span.open(v-if="jsDetail") Hide the speed of execution js
                            span.close(v-else) Show the speed of execution js
                        .resources(v-if="jsDetail")
                            table
                                thead
                                    tr
                                        td Script
                                        td Analysis time
                                        td Total time
                                tbody
                                    tr(v-for="jScript in $store.state.scanner.result.js_time")
                                        td
                                            .url(v-if="jScript.url === 'Other'") {{ jScript.url.replace('Other','Others') }}
                                            a.url(v-else :title="jScript.url" :href="jScript.url" target="_blank") …{{ jScript.url.substr(jScript.url.length - 20) }}
                                        td {{ Math.round(jScript.scriptParseCompile*100)/100 }} ms
                                        td {{ Math.round(jScript.total*100)/100 }} ms
                    competitions(:url="$store.state.scanner.site" :server="$store.state.scanner.server")
                    .clipboard(@click="copyLink")
                        .info
                            .desc Permalink to the results of this test:
                            a.link(:href="`https://loading.express/?test_id=${$store.state.scanner.id}&server=${$store.state.scanner.server}`") https://loading.express/?test_id={{$store.state.scanner.id}}&server={{$store.state.scanner.server}}
                        .btn.small COPY
                        .info-copied Copied from
                        input(type="hidden" id="copyLink" :value="`https://loading.express/?test_id=${$store.state.scanner.id}&server=${$store.state.scanner.server}`")
                    .other-tests
                        p Check your site in other services:
                        a.btn.grey(:href="`https://developers.google.com/speed/pagespeed/insights/?hl=ru&url=${$store.state.scanner.site}`" target="_blank" @click="$sendGoal('pagespeed-click')") PageSpeed Insights
                        a.btn.grey(:href="`https://lh.loading.express/?url=${$store.state.scanner.site}`" target="_blank" @click="$sendGoal('lh-click')") Lighthouse 7.3
                    .pulse.btn.mrg#getmailauditdown(@click="clickMailAuditDown") Get the audit by mail

            SubscribePopup#formmailaudit(v-if="subscr" @close="subscr = false" title="Get an email audit" button_label="Get audited" default_show)

            ThePopup.success-screen(v-else-if="$store.state.scanner.status === 'success'" @close="$store.commit('set_scanner_status', null)")
                .title
                    | Thank you for the request!
                    br
                    | We will analyze your site and contact you.
                .image
                    img.rocket(:src="`/images/rocket.svg`")
                    img.cloud.back(:src="require('~/assets/images/cloud-back.svg')")
                    img.cloud.middle(:src="require('~/assets/images/cloud-middle.svg')")
                    img.cloud.front(:src="require('~/assets/images/cloud-front.svg')")

            ThePopup.hint(v-if="hint_full" @close="hint_full = !hint_full")
                .title
                    | Full load time
                    br
                    | {{ full_time }} sec.
                strong Should not exceed 3 seconds
                p Full load time - the time in which the page is loaded and ready to interact with the user. The less - the better.
                .btn.grey(@click="hint_full = !hint_full") Close
            ThePopup.hint(v-if="hint_request" @close="hint_request = !hint_request")
                .title
                    | Number of HTTP-requests
                    br
                    | {{ $store.state.scanner.result.requests }}
                strong
                    | Google recommends up to 50.
                    br
                    | We recommend up to 100
                p Queries are images, styles, scripts, fonts and any ajax requests. The fewer requests - the better.
                .btn.grey(@click="hint_request = !hint_request") Close
            ThePopup.hint(v-if="hint_size" @close="hint_size = !hint_size")
                .title
                    | Total page size
                    br
                    | {{ full_size_string }}
                strong
                    | Google recommends up to 500 kilobyte.
                    br
                    | We recommend up to 1 megabyte.
                p The volume of all transferred data, including all resources that are downloaded for user interaction with the page.  The less weight - the better.
                .btn.grey(@click="hint_size = !hint_size") Close
            ThePopup.hint(v-if="hint_ttfb" @close="hint_ttfb = !hint_ttfb")
                .title
                    | Server response time
                    br
                    | {{ $store.state.scanner.result.TTFB }} milliseconds
                strong Should not exceed 400 milliseconds
                p The time it takes the server to respond and render the page content. A high value may indicate slow hosting or a problem with the server part of the site. The less time - the better.
                p
                    | What can I do to improve this indicator? Read our article on
                    a(href="https://loading.express/blog/about-speed/kak-uznat-i-sokratit-vremja-otveta-servera/" class="mrg-link" target="_blank") server response speeds
                .btn.grey(@click="hint_ttfb = !hint_ttfb") Close
            ThePopup.hint(v-if="hint_html" @close="hint_html = !hint_html")
                .title
                    | Load time for all resources
                    br
                    | {{ domContentLoaded }} sec.
                strong Should not exceed 3 seconds
                p The time it takes to load all the page resources (images, styles, fonts, scripts) needed for user interaction with the page.  The less time - the better.
                .btn.grey(@click="hint_html = !hint_html") Close
            ThePopup.hint(v-if="hint_fcp" @close="hint_fcp = !hint_fcp")
                .title
                    | First rendering of content
                    br
                    | {{ $store.state.scanner.result.FCP }} ms
                strong Should not exceed 2000 milliseconds
                p The time after which the user begins to see the content of the loaded page. The shorter the time, the better.
                .btn.grey(@click="hint_fcp = !hint_fcp") Close
            ThePopup.hint(v-if="hint_fid" @close="hint_fid = !hint_fid")
                .title
                    | Maximum input delay
                    br
                    | {{ $store.state.scanner.result.FID }} ms
                strong Should not exceed 300 milliseconds
                p The time between the user's action and the browser's response. Perceived by the user as a slow response from the site. The less delay, the better.
                .btn.grey(@click="hint_fid = !hint_fid") Close
            ThePopup.hint(v-if="hint_js" @close="hint_js = !hint_js")
                .title
                    | Lead time JavaScript
                    br
                    | {{ $store.state.scanner.result.EvaluateScript }} ms
                strong Should not exceed 2000 milliseconds
                p The execution time of all the scripts on the page that are needed to render the page. This includes the execution time of statistical and third-party scripts. The less time, the better.
                .btn.grey(@click="hint_js = !hint_js") Close
            ThePopup.hint(v-if="hint_interactive" @close="hint_interactive = !hint_interactive")
                .title
                    | Time before interaction
                    br
                    | {{ full_time }} sec.
                strong Should not exceed 3 seconds
                p The time in which the page is loaded and ready to interact with the visitor. The less time - the better.
                .btn.grey(@click="hint_interactive = !hint_interactive") Close
            callback#formphoneaudit(v-if="callback" @close="callback = false")

</template>

<script>
    import FormButton from "~/components/FormButton"
    import TheContact from "~/components/TheContact"
    import axios from "axios"
    import config from "~/config"
    import ThePopup from "~/components/ThePopup";
    import elementOffset from "~/plugins/elementOffset";
    import Fz from "~/components/fz";
    import FormWrapper from "~/components/FormWrapper";
    import callback from "~/components/callback";
    import SubscribePopup from "~/components/SubscribePopup";
    import TheHeader from "~/components/TheHeader";
    import coreWebVitals from "~/components/coreWebVitals";
    import competitions from "~/components/competitions";

    const API_URL = `${config.API}/api`;
    const MAIL_URL = `${config.API}/mail`;

    /** @namespace text.analysis **/
    /** @namespace text.html **/
    /** @namespace text.sec **/
    /** @namespace text.score_gzip **/
    /** @namespace text.full **/
    /** @namespace text.ttfb **/
    /** @namespace text.text.perfect **/
    /** @namespace text.text.good **/
    /** @namespace text.text.bad **/

    export default {
        name: "ScanScreen",
        computed: {
            other_site(){
                return this.$store.state.scanner.site.indexOf('loading.express') > -1;
            },
            show_email() {
                return this.$store.state.cookie.day_scans > 1 && !this.$store.state.cookie.already_subscribed
            },

            full_size_string() {
                let full_size = +this.$store.state.scanner.result.size;
                let size_unit = "B";
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "KB";
                }
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "MB";
                }
                return `${ full_size } ${ size_unit }`
            },
            offscreen_image_string() {
                let full_size = +this.$store.state.scanner.result.offscreen_image;
                let size_unit = "B";
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "KB";
                }
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "MB";
                }
                return `${ full_size } ${ size_unit }`
            },
            cache_save_string() {
                let full_size = +this.$store.state.scanner.result.cache_save;
                let size_unit = "B";
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "KB";
                }
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "MB";
                }
                return `${ full_size } ${ size_unit }`
            },
            compress_save_string() {
                let full_size = +this.$store.state.scanner.result.compress_save;
                let size_unit = "B";
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "KB";
                }
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "MB";
                }
                return `${ full_size } ${ size_unit }`
            },
            webp_save_string() {
                let full_size = +this.$store.state.scanner.result.webp_save;
                let size_unit = "B";
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "KB";
                }
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "MB";
                }
                return `${ full_size } ${ size_unit }`
            },
            screenshot() {
                return this.$store.state.scanner.result.screenshot;
            },
            domContentLoaded() {
                return (this.$store.state.scanner.result.domContentLoaded / 1000).toFixed(1)
            },
            full_time() {
                return (this.$store.state.scanner.result.load / 1000).toFixed(1)
            },
            rate() {
                return this.grade(this.full_time, 3, 12)
            },
            score_compress() {
                return Math.floor(this.$store.state.scanner.result.score_compress / 10)
            },
            score_zip() {
                return Math.floor(this.$store.state.scanner.result.score_zip / 10)
            },
            score_cache() {
                return Math.floor(this.$store.state.scanner.result.score_cache_acc / 10)
            },
            testDate() {
                return new Date(this.$store.state.scanner.result.testTime).toLocaleString("ru");
            },
            ttfb_rate() {
                return this.grade(this.$store.state.scanner.result.TTFB, 400, 2000)
            },
            EvaluateScript_rate() {
                return this.grade(this.$store.state.scanner.result.EvaluateScript, 2000, 10000)
            },
            FCP_rate() {
                return this.grade(this.$store.state.scanner.result.FCP, 2000, 8000)
            },
            FID_rate() {
                return this.grade(this.$store.state.scanner.result.FID, 300, 2000)
            },
            onload_rate() {
                return this.grade(this.$store.state.scanner.result.domContentLoaded, 3000, 8000)
            },
            LastInteractive_rate() {
                return this.grade(this.$store.state.scanner.result.LastInteractive, 3000, 10000)
            },
            Requests_rate() {
                return this.grade(this.$store.state.scanner.result.requests, 99, 200)
            },
            Size_rate() {
                return this.grade(this.$store.state.scanner.result.size, 1148576, 10485760)
            },
            total_rate() {
                const minRate = Math.min(
                  this.rate,
                  this.ttfb_rate,
                  this.EvaluateScript_rate,
                  this.LastInteractive_rate,
                  this.FCP_rate,
                  this.Size_rate,
                  this.Requests_rate,
                  this.onload_rate,
                  this.FID_rate
                );
                const avgRate = (this.rate + this.ttfb_rate + this.EvaluateScript_rate + this.LastInteractive_rate + this.FCP_rate + this.Size_rate + this.Requests_rate + this.onload_rate + this.FID_rate) / 9;
                return Math.round((avgRate + minRate) / 2)
            },
            site_domain() {
                return this.$store.state.scanner.site.replace("http://", "").replace("https://", "").split("/")[0]
            },
            site_full_url() {
                return `http://${this.$store.state.scanner.site.replace("http://", "").replace("https://", "")}`
            }
        },
        components: {
            TheHeader,
            Fz,
            TheContact,
            ThePopup,
            FormButton,
            FormWrapper,
            callback,
            coreWebVitals,
            SubscribePopup,
            competitions
        },
        watch: {
            "$store.state.scanner.status"(value, oldValue) {
                if (value !== oldValue && value === "start") this.start()
            }
        },
        methods: {
            // oldScreenshot(event) {
            //     event.target.src = this.$store.state.scanner.result.screenshot.replace("http://", "https://");
            // },
            getExternalResources() {
                let domains_group = [];
                let currentDomain = new URL(this.$store.state.scanner.site);
                for(let item of this.$store.state.scanner.result.requestsItem){
                    if(item.url.indexOf('data:') < 0){
                        let urln = new URL(item.url);
                        if(urln.hostname != currentDomain.hostname){
                            if(!domains_group[urln.hostname]) {
                                domains_group[urln.hostname] = [];
                            }
                            domains_group[urln.hostname].push(item);
                        }
                    } else {
                        if(!domains_group['inline']) {
                            domains_group['inline'] = [];
                        }
                        domains_group['inline'].push(item);
                    }
                }
                console.log(domains_group);
                return domains_group;
            },
            getUrlPath(url) {
                if(!url.startsWith('data:')){
                    let urln = new URL(url);
                    if(urln.pathname.length < 40){
                        return urln.pathname;
                    } else {
                        return urln.pathname.substr(0,20) + '...' + urln.pathname.substr(urln.pathname.length - 20,urln.pathname.length);
                    }
                } else {
                    return 'Built-in ' + url.replace(/data:(.+?)[;,].+/, '$1');
                }
            },
            size_human(size) {
                let full_size = +size;
                let size_unit = "B";
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "KB";
                }
                if (full_size > 1024) {
                    full_size = (full_size / 1024).toFixed(2);
                    size_unit = "MB";
                }
                return `${ full_size } ${ size_unit }`
            },
            copyLink() {
                let linkToCopy = document.querySelector('#copyLink');
                linkToCopy.setAttribute('type', 'text');
                linkToCopy.select();
                try {
                    document.execCommand('copy');
                    var linkText = document.querySelector('.clipboard');
                    linkText.classList.add("copied");
                    setTimeout(function(){
                        linkText.classList.remove("copied")
                    },1000);
                } catch (err) {
                }
                linkToCopy.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges();
                this.$sendGoal("copy-audit-link");
            },
            go_to_scan() {
                window.scrollTo(0, elementOffset(this.$el).top)
            },
            getMessengerLinks() {
                this.getAudit = !this.getAudit;
                this.$sendGoal("open-messanger-link");
            },
            moreTarget(){
                this.moreDetail =! this.moreDetail;
                this.$sendGoal("show-me-more");
            },
            clickMessengerLink() {
                this.$sendGoal("click-to-messenger");
            },
            clickMailAudit() {
                this.subscr = true;
                this.$sendGoal("click-email-audit");
                us_client.fetchAjaxForm();
            },
            clickMailAuditDown() {
                this.subscr = true;
                this.$sendGoal("click-email-audit-down");
                us_client.fetchAjaxForm();
            },
            clickPhoneAudit() {
                this.callback = true;
                this.$sendGoal("click-phone-audit");
                us_client.fetchAjaxForm();
            },
            decQueue() {
                if(this.queue > 1) {
                    this.queue--;
                }
                this.$store.commit("set_scanner_queue", this.queue);
            },
            get_test_result(test_id, server) {
                this.getAudit = false;
                this.queue = 0;
                if (test_id) {
                    this.$router.push({query: {test_id, server}});
                }
                if (this.$store.state.scanner.status) {
                    return axios.post(API_URL, {id: test_id, server: server}).then(({data}) => {
                        if (data.error) {
                            if (data.error === 404) return this.close();
                            this.$store.commit("set_scanner_status", "error");
                            if (data.url) this.$store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                            this.$cookies.remove("current_site_test");
                            clearInterval(this.textInterval);
                            clearInterval(this.horrorInterval);
                            clearInterval(this.percentsInterval);
                            this.float.text = "Failed to complete the scan";
                            axios.post(MAIL_URL, {
                                site: this.$store.state.scanner.site,
                                email: this.$store.state.scanner.email,
                                client_id: this.$cookies.get("utmstat_client_id"),
                                mail_subject: 'Не удалось завершить сканирование ' + this.$store.state.scanner.site + ' #ERROR',
                                comment: 'Failed to complete the scan ' + this.$store.state.scanner.site
                            });
                            return false;
                        }
                        if (data.wait) {
                            if (data.url) this.$store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                            this.$store.commit("set_scanner_status", "wait");
                            // if(this.$store.state.cookie.already_subscribed) {
                                this.$store.commit("set_scanner_queue", data.queue + 1);
                                this.testTimeout = setTimeout(this.get_test_result, 1000, test_id, server);
                            // } else {
                            //     this.queue = Math.floor(Math.random() * (50 - 30)) + 30;
                            //     setInterval(this.decQueue, 2000);
                                // this.testTimeout = setTimeout(this.get_test_result, 60000, test_id, server);
                            // }
                            return true;
                        }
                        if (data.progress) {
                            // if(!this.makeScreen) {
                            //     this.makeScreen = true;
                            //     let surl = this.$store.state.scanner.site;
                            //     axios.post('https://screen.loading.express/', {
                            //         url: surl,
                            //         id: test_id
                            //     }).then(({data}) => {
                            //
                            //     });
                            // }
                            if (data.url) this.$store.commit("set_scanner_site", data.url.replace(/^https?:\/\//, ""));
                            this.$store.commit("set_scanner_status", "progress");
                            if (!this.startTime) {
                                this.startTime = Date.now();
                                this.float.percents = 0;
                                this.percentsInterval = setInterval(() => {
                                    let delta = Math.round((Date.now() - this.startTime) / 1000);
                                    if (delta <= 10) {
                                        this.float.percents = Math.round((50 / 10) * delta)
                                    } else {
                                        delta -= 10;
                                        if (delta <= 20) {
                                            this.float.percents = delta + 50
                                        } else {
                                            delta -= 20;
                                            if (delta <= 10) {
                                                this.float.percents = 90 + Math.round((9 / 10) * delta)
                                            } else {
                                                this.float.percents = 99
                                            }
                                        }
                                    }
                                }, 100);
                            }
                            this.testTimeout = setTimeout(this.get_test_result, 1000, test_id, server);
                            return true;
                        }
                        if (data.result) {
                            if (data.url) this.$store.commit("set_scanner_site", data.url);
                            clearInterval(this.textInterval);
                            clearInterval(this.percentsInterval);

                            for (let input of this.$el.getElementsByTagName("input")) {
                                input.setAttribute("autocorrect", "off")
                            }

                            this.percentsInterval = setInterval(() => {
                                if (this.startTime && this.float.percents < 100) {
                                    this.float.percents++
                                } else {
                                    clearInterval(this.percentsInterval);
                                    this.float.text = "Completed!";
                                    this.$store.commit("set_scanner_status", "result");
                                    this.float.startTime = 0;
                                }
                            }, 10);
                            if(data.result.requests)
                            if (data.result.score_compress < 0) data.result.score_compress = 100;
                            this.$store.commit("set_scanner_result", data.result);
                            if(this.total_rate === 10) {
                                axios.post(MAIL_URL, {
                                    site: this.$store.state.scanner.site,
                                    email: this.$store.state.scanner.email,
                                    screenshot: this.screenshot,
                                    html_value: this.domContentLoaded,
                                    requests_value: this.$store.state.scanner.result.requests,
                                    full_time: this.full_time,
                                    size_value: this.full_size_string,
                                    current_url: document.location.href,
                                    domSize: this.$store.state.scanner.result.domSize,
                                    rate: this.rate,
                                    score_compress: this.score_compress,
                                    score_zip: this.score_zip,
                                    score_cache: this.score_cache,
                                    FID: this.FID,
                                    FCP: this.FCP,
                                    offscreen_image: this.offscreen_image,
                                    TTFB: this.$store.state.scanner.result.TTFB,
                                    LastInteractive: this.$store.state.scanner.result.LastInteractive,
                                    EvaluateScript: this.$store.state.scanner.result.EvaluateScript,
                                    total_rate: this.total_rate,
                                    CMS: this.$store.state.scanner.result.CMS,
                                    server: this.$store.state.scanner.server,
                                    client_id: this.$cookies.get("utmstat_client_id"),
                                });
                            }
                            return true;
                        }
                    }).catch(error => {
                        this.close();
                        console.error(error)
                    })
                }
            },
            wait_result(test_id,server) {
                this.$store.commit("set_scanner_status", "wait");
                this.$store.commit("set_scanner_id", test_id);
                this.$store.commit("set_scanner_server", server);
                this.get_test_result(test_id,server);
            },
            start() {
                setTimeout(this.go_to_scan, 50);

                this.$sendGoal("check-speed");

                this.textInterval = setInterval(() => {
                    this.current_text_index++;
                    if (this.current_text_index >= this.analysis.length) this.current_text_index = 0;
                    this.float.text = this.analysis[this.current_text_index];
                }, 3000);
                this.horrorInterval = setInterval(() => {
                    this.current_text_index++;
                    if (this.current_text_index >= this.horrorTextList.length - 1) {
                        this.current_text_index = 0;
                    }
                    this.horror_opacity = 0;
                    return setTimeout(() => {
                        this.horror_opacity = 1;
                        this.horror_text = this.horrorTextList[this.current_text_index];
                    }, 300)
                }, 7000);

                this.stopTest();

                this.$store.commit("set_scanner_status", "wait");
                if(this.$store.state.scanner.server.length < 1){
                    this.$store.commit("set_scanner_server", 'main');
                }
                return axios.post(API_URL, {url: this.$store.state.scanner.site, server: this.$store.state.scanner.server}).then(({data}) => {
                    // this.makeScreen = false;
                    /** @namespace data.testID **/
                    if (data && data.testID) {
                        this.$cookies.set("current_site_test", JSON.stringify({
                            site: this.$store.state.scanner.site,
                            email: this.$store.state.scanner.email,
                            id: data.testID
                        }), {maxAge: 7 * 24 * 3600});
                        return this.wait_result(data.testID,this.$store.state.scanner.server);
                    }
                    return console.error(data.error);
                }).catch(console.error)
            },
            grade(value, min, max) {
                let rate = 10;
                if (+value >= max) {
                    rate = 0;
                } else {
                    if (+value <= min) {
                        rate = 10;
                    } else {
                        rate = Math.floor((1 - (+value - min) / (max - min)) * 10)
                    }
                }
                return rate
            },
            open_social(href) {
                window.open(href, "", "width=600,height=600");
            },
            submit() {
                this.$store.commit("set_scanner_status", "success");
                this.$sendGoal("lead-after-check");
                if(this.total_rate === 10) {
                    return axios.post(MAIL_URL, {
                        site: this.$store.state.scanner.site,
                        email: this.$store.state.scanner.email,
                        screenshot: this.screenshot,
                        html_value: this.domContentLoaded,
                        requests_value: this.$store.state.scanner.result.requests,
                        full_time: this.full_time,
                        size_value: this.full_size_string,
                        current_url: document.location.href,
                        rate: this.rate,
                        score_compress: this.score_compress,
                        score_zip: this.score_zip,
                        score_cache: this.score_cache,
                        FID: this.FID,
                        FCP: this.FCP,
                        offscreen_image: this.offscreen_image,
                        TTFB: this.$store.state.scanner.result.TTFB,
                        EvaluateScript: this.$store.state.scanner.result.EvaluateScript,
                        LastInteractive: this.$store.state.scanner.result.LastInteractive,
                        total_rate: this.total_rate,
                        phone: this.phone,
                        CMS: this.$store.state.scanner.result.CMS,
                        client_id: this.$cookies.get("utmstat_client_id"),
                    });
                } else {
                    return true;
                }
            },
            close() {
                this.$cookies.remove("current_site_test");
                this.$store.commit('set_scanner_status', null);
                this.$router.push({query: {}})
            },
            stopTest() {
                this.$store.commit('set_scanner_status', null);
                this.startTime = 0;
                clearTimeout(this.testTimeout)
            },
            loadTestData() {
                /** @namespace this.$route **/
                if (this.$route.query.test_id) {
                    if (this.$route.query.server) {
                        this.$store.commit("set_scanner_server", this.$route.query.server);
                    } else {
                        this.$store.commit("set_scanner_server", 'main');
                    }
                    this.wait_result(this.$route.query.test_id,this.$store.state.scanner.server);
                    return setTimeout(this.go_to_scan, 300);
                }
                if (this.$route.query.scan_url) {
                    if(this.$store.state.scanner.server && this.$store.state.scanner.server.length > 1){
                        this.$store.commit("set_scanner_server", this.$route.query.server);
                    } else {
                        this.$store.commit("set_scanner_server", 'main');
                    }
                    this.$store.commit("set_scanner_site", this.$route.query.scan_url);
                    this.$store.commit("set_scanner_status", "wait");
                    return setTimeout(this.start, 300);
                }
            }
        },
        mounted() {
            this.stopTest();
            this.floatInterval = setInterval(() => {
                if (this.$store.state.scanner.status) {
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                    const offset = elementOffset(this.$el);
                    if (scrollTop + document.documentElement.clientHeight <= offset.top+200) {
                        this.float.show = true;
                        this.float.top = "auto";
                        this.float.bottom = 0;
                    } else if (scrollTop >= offset.top + this.$el.offsetHeight - 40) {
                        this.float.show = true;
                        this.float.top = 50+'px';
                        this.float.bottom = "auto";
                    } else {
                        this.float.show = false
                    }
                }
            }, 50);

            this.loadTestData()
        },
        beforeDestroy() {
            clearInterval(this.floatInterval);
            clearInterval(this.textInterval);
            clearInterval(this.horrorInterval);
            this.stopTest();
        },
        data() {
            return {
                phone: "",
                float: {
                    show: true,
                    top: 0,
                    bottom: "auto",
                    text: "Please wait for...",
                    percents: 0
                },
                // makeScreen: false,
                current_text_index: 0,
                floatInterval: null,
                textInterval: null,
                percentsInterval: null,
                startTime: 0,
                testTimeout: null,
                horror_text: "Up to 88% of customers will not return to a slow site",
                horrorInterval: null,
                current_horror_index: 0,
                horror_opacity: 1,
                horrorTextList: [
                  "Up to 88% of customers will not return to a slow site.",
                  "1 second of slowdown costs $1.6 billion a year for Amazon.",
                  "Fast sites get 20% more organic traffic.",
                  "Fast sites get 14% more page views.",
                  "Fast sites give 7% to 12% more conversions than slow ones."
                ],
                "analysis": [
                    "Server response check in progress...",
                    "HTML loading speed is being checked...",
                    "Mobile version loading speed is being checked...",
                    "Image compression check in progress...",
                    "Checking the loading speed of styles...",
                    "Checking the speed of JavaScript execution...",
                    "Checking page rendering speed...",
                    "Checking page volume...",
                    "The number of requests to the server is being counted...",
                    "Report generation in progress..."
                ],
                moreDetail: true,
                resourcesDetail: true,
                hint_full: false,
                hint_request: false,
                hint_size: false,
                hint_ttfb: false,
                hint_html: false,
                hint_fcp: false,
                hint_fid: false,
                hint_js: false,
                hint_interactive: false,
                hint_lazy: false,
                getAudit: false,
                jsDetail: false,
                callback: false,
                subscr: false,
                queue: 0
            }
        }
    }
</script>


<style lang="stylus">
    @require "~assets/style/VAR.styl"
    .hint
        .title
            font-size 28px
        p
            margin-bottom 20px
            text-align left
            font-weight normal
        a
            color blue
        .btn.grey
            background #999
            margin-bottom 15px
    .scanner-screen
        background #e7e7e7
        .close-result-btn
            width auto
            margin 20px auto 0
        .float-scan-title
            position fixed
            padding 5px
            left 0
            width 100%
            z-index 9
            .text
                display inline-flex
                margin auto
                max-width 100%
                background #fff
                box-shadow 0 0 5px rgba(0, 0, 0, .5)
                padding 5px
                align-items flex-start
                border-radius 3px
                strong
                    margin-right 5px
                    font-weight bold
                p
                    margin 0

        small
            display block
            a
                color blue
                text-decoration none
        .bl
            color blue
            text-decoration none
            display block
        .mrg
            margin 5px
            width calc(100% - 10px)
        .image
            width 260px
            height 260px
            overflow hidden
            border-radius 50%
            margin 10px auto 0
            background night-sky url("~assets/images/sky-bottom.svg")
            background-size 600px
            background-position center
            position relative
            .rocket
                position absolute
                left 50%
                top 30px
                width 110px
                margin-left -55px
                z-index 3
            .cloud
                position absolute
                &.front
                    bottom 0
                    left 0
                    width 550px
                &.middle
                    bottom 0
                    left 10px
                    width 480px
                &.back
                    bottom 0
                    left -20px
                    width 400px
            .horror
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                color #fff
                padding 20px
                display flex
                align-items center
                justify-content center
                text-align center
                transition .3s
                z-index 5
        .error-screen
            .title
                small
                    font-size .5em

        .wait-screen
            .text
                font-style italic
                font-size 15px
                margin 10px auto 0
                max-width 100%
                text-align center
                .analysis
                    height 30px
                .lnk
                    color blue
            .title
                span
                    margin-left:10px
                a
                    color blue
                    text-decoration none
            .banner
                margin-top 20px
                display flex
                flex-wrap wrap
                justify-content center
                a
                    max-width 240px
                    margin 5px

        .result-screen
            .title
                margin-bottom 30px
                width 100%
                span
                    margin-left:10px
            .text
                max-width 100%
                text-align left
                strong
                    display block
                    margin-bottom 30px
                .alert
                    background rgba(255, 204, 0, 0.8)
                    border-radius 3px
                    padding 10px
                    margin-top 10px
                    font-size 16px
                    display block
                    strong
                        margin-bottom 0
                        margin-top 10px
            .screenshot
                margin-bottom 30px
                position relative
                min-width 210px
                .date
                    background #fff
                    padding 10px
                    border-radius 3px
                    color dark
                    max-width 180px
                    margin 30px auto 0 auto
                    font-size 16px
                .banner
                    margin 20px auto
                    max-width 180px
                    a
                        margin: 5px 0

                .sticky
                    position sticky
                    top 70px
                    .screen
                        position relative
                        img
                            display block
                            max-width 100%
                            height 288px
                            width 180px
                            max-height 288px
                            margin auto
                            box-shadow 0 20px 50px rgba(0, 36, 51, .3)
                            border-radius 15px
                        .iphone
                            background url("~assets/images/iphone.svg") center no-repeat
                            position absolute
                            top -12px
                            left 1px
                            width 100%
                            height 308px
                        .fake
                            background #d52055
                            color #fff
                            position absolute
                            top 70px
                            padding 30px
                            border-radius 3px
                            border 2px solid #fff
                            box-shadow 0 0 50px rgba(0,0,0,.5)
                            opacity 0.9
            .result
                text-align left
                font-weight normal
                .btn.grey
                    span.open
                        position relative
                        &:before
                            content '▾ '
                    span.close
                        position relative
                        &:before
                            content '▸ '
                .resources
                    overflow auto
                    a
                        color #01a0e2
                    .url
                        max-width 100%
                        overflow hidden
                        white-space nowrap
                        text-overflow ellipsis
                        color #335766

                    .tr
                        display flex
                        justify-content flex-start
                        align-items stretch
                        max-width 100%
                    width 100%
                    border-bottom 5px solid #ccc
                    table
                        width 100%
                        font-size 16px
                        thead
                            tr
                                td
                                    background #bbb
                                    padding 5px
                                    margin-bottom 5px
                                    color: #444
                                    text-align center
                                    overflow hidden
                                    text-overflow ellipsis
                                    font-weight bold
                                    &:first-child
                                        text-align left
                                    &:last-child
                                        text-align right
                        tbody
                            tr
                                td
                                    padding 5px
                                    text-align center
                                    &:first-child
                                        text-align left
                                    &:last-child
                                        text-align right
                                &:nth-child(odd) td
                                    background #f5f5f5
                                    &:first-child
                                        border-radius 3px 0 0 3px
                                    &:last-child
                                        border-radius 0 3px 3px 0
                                        white-space nowrap
                            tr.all
                                td
                                    font-weight bold
                .other-tests
                    display flex
                    flex-wrap wrap
                    p
                        width 100%
                        max-width 100%
                        margin 0 5px
                    a
                        margin 5px
                        background #999
                        width calc(50% - 10px)
                .clipboard
                    padding 10px
                    margin-top 10px
                    margin-bottom 10px
                    background #f5f5f5
                    color #999
                    border-radius 3px
                    position relative
                    display flex
                    cursor pointer
                    flex-wrap wrap
                    justify-content space-between
                    .btn.small
                        padding 3px 5px
                        background #999
                        margin-top 10px
                        border-radius 3px
                    .desc
                        font-weight normal
                        font-size 16px
                    .info
                        max-width 100%
                    .info-copied
                        display flex;
                        width 100%
                        height 100%
                        border-radius 3px
                        color #000
                        background rgba(255,255,255,0.5)
                        -webkit-backdrop-filter blur(3px)
                        align-items center
                        justify-content center
                        opacity 0
                        position absolute
                        transition all 200ms ease-in-out
                        top 0
                        left 0
                    &.copied
                        .info-copied
                            opacity 1
                    .link
                        color blue
                        padding-left 20px
                        background url("~assets/images/link-blue.svg") no-repeat left center / 16px
                        text-overflow ellipsis
                        overflow hidden
                        display block
                        white-space nowrap
                .score
                    display flex
                    align-items flex-start
                    justify-content center
                    flex-wrap wrap
                    .value
                        min-width 240px
                        width 100%
                        display block
                        padding 20px
                        background #fff
                        border-radius 3px
                        text-align center
                        margin-bottom 30px
                        strong
                            font-size 72px
                            display block
                        p.more
                            color dark
                            display block
                            margin-bottom 10px
                        .speed
                            font-size 27px
                            text-align center
                            font-weight bold
                            text-transform uppercase
                        form
                            padding 0
                            background none
                            .form-item
                                padding 5px
                                height auto
                                margin 0 0 20px 0
                                border-radius 3px
                                font-size 18px
                            .btn.retest
                                color #01a0e2
                                text-decoration none
                                font-weight bold
                                background none
                                text-transform none
                                font-size 18px
                                height auto
                                min-height 0
                                padding 0
                                &:hover
                                    box-shadow none
                            label.fz-block
                                margin-top 10px
                                color dark
                                text-align left
                    .rate-0
                        color #D31E53
                    .rate-1
                        color #D31E53
                    .rate-2
                        color #D31E53
                    .rate-3
                        color #D95350
                    .rate-4
                        color #E07647
                    .rate-5
                        color #E89438
                    .rate-6
                        color #F1B41C
                    .rate-7
                        color #d1c152
                    .rate-8
                        color #AEB83B
                    .rate-9
                        color #87B846
                    .rate-10
                        color #52B94F
                hr
                    border 0
                    border-top 1px solid #D9DEE1
            .close-result-btn
                background none
                color dark
            .rate-bar
                padding 10px 0
                display flex
                max-width 180px
                margin auto
                i
                    width calc((100% - 27px) / 10)
                    margin-right 3px
                    height 5px
                    &:nth-child(1)
                        background #D31E53
                    &:nth-child(2)
                        background #D31E53
                    &:nth-child(3)
                        background #D95350
                    &:nth-child(4)
                        background #E07647
                    &:nth-child(5)
                        background #E89438
                    &:nth-child(6)
                        background #F1B41C
                    &:nth-child(7)
                        background #D1B72D
                    &:nth-child(8)
                        background #AEB83B
                    &:nth-child(9)
                        background #87B846
                    &:nth-child(10)
                        background #52B94F
                        margin-right 0
            .preview
                display flex
                justify-content space-between
                font-size 14px
                font-weight bold
                padding 20px 0
                overflow-x auto
                margin-top 10px
                .preview-item
                    display flex
                    flex-direction column
                    align-items center
                    margin 8px
                    img
                        width 60px
                        border-radius 3px
                        box-shadow 0 0 10px rgba(0,0,0,.2)
                        margin-bottom 5px
                    &:last-child
                        margin-right 0
                    &:first-child
                        margin-left 0

            .metrics
                display flex
                align-items stretch
                justify-content space-between
                border 5px solid #ccc
                margin-top 10px
                border-radius 3px
                border-bottom 0
                flex-direction column
                flex-wrap wrap
                .metrics-item
                    width 100%
                    padding 10px
                    display flex
                    flex-wrap wrap
                    justify-content space-between
                    position relative
                    &::before
                        content ''
                        display block
                        width 100%
                        height 5px
                        position absolute
                        bottom 0
                        left 0
                    &.rate-0::before
                        background #D31E53
                    &.rate-1::before
                        background #D31E53
                    &.rate-2::before
                        background #D31E53
                    &.rate-3::before
                        background #D95350
                    &.rate-4::before
                        background #E07647
                    &.rate-5::before
                        background #E89438
                    &.rate-6::before
                        background #F1B41C
                    &.rate-7::before
                        background #d1cc00
                    &.rate-8::before
                        background #AEB83B
                    &.rate-9::before
                        background #87B846
                    &.rate-10::before
                        background #52B94F
                    .metrics-item-title
                        font-size 14px
                        width 100%
                    .metrics-item-value
                        font-size 36px
                        font-weight bold
                    .metrics-item-help
                        display flex
                        align-items center
                        justify-content center
                        cursor pointer
                        border 1px solid blue
                        color blue
                        width 20px
                        height 20px
                        border-radius 50%
                        font-size 14px
                    &.small
                        .metrics-item-value
                            font-size 28px
                            font-weight normal
                .line
                    display flex
                    padding 10px
                    justify-content space-between
                    width 100%
                    font-size 14px
                    border-bottom 5px solid #ccc
                    align-items center
                    .value
                        font-size 20px
                        white-space nowrap
                .btn.grey
                    color #335766
                    background #ccc

            form
                background #F0F5F7
                border-radius 3px
                padding 30px
                width 100%
                font-weight normal
                text-align center
                p
                    display inline
                .getMessangerLinks
                    margin-top 20px
                    &.icon:before
                        content ''
                        display block
                        width 16px
                        height 16px
                        min-width 16px
                        min-height 16px
                        margin-right 5px
                        background url("~assets/images/mail.svg") no-repeat center / contain
                span.empty
                    & + p
                        &::first-letter
                            text-transform uppercase
                .input
                    margin 30px auto 0
                    max-width: 750px
                    .form-item
                        margin 0
                        border-bottom 0
                    .btn
                        display block
                        white-space nowrap
                        padding 0 20px
            .share
                display flex
                margin-top 20px
                justify-content center
                min-height 50px
                flex-direction column
                .item
                    width 100%
                    min-height 50px
                    background-position 10px center
                    background-repeat no-repeat
                    display flex
                    color white
                    align-items center
                    text-decoration none
                    padding 10px 0 10px 40px
                    margin-bottom 10px
                    border-radius 3px
                    text-align left
                    cursor pointer
                    &:last-child
                        margin-bottom 0
                    &.vk
                        background-image url("~assets/images/vk.svg")
                        background-size 20px
                        background-color #45668E
                    &.fb
                        background-image url("~assets/images/fb.svg")
                        background-size 10px
                        background-color #3B5998
                    &.tg
                        background-image url("~assets/images/tg.svg")
                        background-size 20px
                        background-color #0088CC
                    &.phone
                        background-image url("~assets/images/phone.svg")
                        background-size 20px
                        background-color purple
                    &.mail
                        background-image url("~assets/images/mail.svg")
                        background-size 20px
                        background-color #999
                    &.green
                        background-color #52b94f
                    &.grey
                        background-color #47494e
                    &.red
                        background-color #d52055

    @media screen and (min-width: tablet-width)
        .scanner-screen
            .result-screen
                display flex
                flex-wrap wrap
                .title
                    margin-bottom 50px
                    .bl
                        display inline
                .screenshot
                    width 30%
                    margin-bottom 0
                .result
                    width 70%
                    padding-left 40px
                    .clipboard
                        flex-wrap nowrap
                        .info
                            width calc(100% - 150px)
                        .btn.small
                            width 150px
                            height auto
                            margin-top 0
                .rate-bar
                    margin 0 auto 10px auto
                .metrics
                    flex-direction row
                    .metrics-item
                        border-right 5px solid #ccc
                        width 33.33%
                        cursor pointer
                        &.no-border
                            border-right 0
                        &:hover
                            background #f7f7f7
                            .metrics-item-help
                                border 2px solid blue
                                font-weight bold
                form
                    .input
                        display flex
                        .form-item
                            width 100%
                            border-right 0
                            border-bottom 1px solid #D9DEE1
                            font-size 16px

    @media screen and (min-width: desktop-width)
        .scanner-screen
            .float-scan-title
                cursor pointer
                .text
                    padding 10px
            .image
                width 450px
                height 450px
                background-size 800px
                margin-top 20px
                .horror
                    font-size 26px
                    padding 30px
                .rocket
                    width 200px
                    top 55px
                    margin-left -100px
                .cloud
                    &.front
                        width 800px
                        left 20px
                    &.middle
                        width 700px
                        left 50px
                        bottom 20px
                    &.back
                        width 550px
                        bottom 20px
            .result-screen
                .result
                    .score
                        flex-wrap nowrap
                        .value
                            width auto
                            margin-right 30px
                            margin-bottom 0
                .rate
                    font-size 24px
                .share
                    justify-content flex-start
                    .item
                        &:hover
                            opacity .7
            .wait-screen
                .text
                    margin-top 20px
            summary
                cursor pointer
    .mrg-link
        margin 0 8px
</style>
